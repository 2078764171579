import findKey from "lodash/findKey"

import {
  ACCESS_CONTROL, ADMIN, ADMIN_SPACES, ADMIN_USERS, ANYTHING_DB, APP, APPS, AU_MANAGEMENT, AUTHENTICATE, CATEGORIES, CLUSTER_MANAGEMENT,
  CLUSTER_MANAGEMENT_OLD, CUSTOM_QUERIES, DATA_VIZ, DISTRIBUTIONS,
  DOCS, DOCS_POLICY_RESOURCES, ECP_IMAGES, EDGE_APPS, EDGE_OPS, EDGE_PROVISIONINGS,
  EVERY_THING, FUNCTIONS, HIGH_FREQ_DATA, HOME_PAGE, LABELS, LINK_ALTAIR_ONE, LOGIN,
  LOGOUT, MODELS, OBJECT_STORAGE, OLD_EVERY_THING, PACKAGES, PROPERTIES_HISTORY_CHART, RESOURCES, ROLES,
  ROLLOUTS, ROOT, SERVERLESS, SETTINGS, SPACE_GENERAL, SPACE_PICKER, SPACE_SETTINGS, STREAMS, SUPPORT, TRIGGERS, USAGE_HISTORY_CHART, USERS, VARIABLES_STORAGE} from "constants/routes"

// To add a route, follow the steps listed
// 1) add CONST for the name of the page/var to constants/routes.js
//    make sure to import it into here

// 2) add a page with the endpoint name
//    this is what will appear in the url
export const endpoints = {
  [ROOT]: "",
  [PROPERTIES_HISTORY_CHART]: "properties-history-chart",
  [USAGE_HISTORY_CHART]: "usage-history-chart",
  [LINK_ALTAIR_ONE]: "linkAltairOne",
  [LOGIN]: "signin",
  [AUTHENTICATE]: "authenticate",
  [APP]: "app",

  [SETTINGS]: "settings",
  [LOGOUT]: "logout",

  [DOCS]: "documentation",
  [DOCS_POLICY_RESOURCES]: "documentation-policy-resources",
  [SUPPORT]: "support",

  [ANYTHING_DB]: "anything-db",
  [CATEGORIES]:        "categories",
  [MODELS]:           "models",
  [OLD_EVERY_THING]: "everything",
  [EVERY_THING]:       "things",
  [CUSTOM_QUERIES]: "custom-queries",

  [SERVERLESS]: "user-functions",
  [FUNCTIONS]: "functions",
  [TRIGGERS]: "triggers",

  [EDGE_OPS]: "edge-ops",
  [CLUSTER_MANAGEMENT_OLD]: "fleet-management",
  [CLUSTER_MANAGEMENT]: "fleet-management",
  [RESOURCES]: "resource-catalog",
  [EDGE_APPS]: "edge-applications",
  [EDGE_PROVISIONINGS]: "edge-provisionings",
  [PACKAGES]: "marketplace",
  [ECP_IMAGES]: "images",
  [DISTRIBUTIONS]: "edge-distributions",
  [ROLLOUTS]: "edge-rollouts",

  [LABELS]: "labels",

  [STREAMS]: "streams",
  [DATA_VIZ]: "data-vizualizations",

  [ACCESS_CONTROL]: "access-control",
  [USERS]: "users",
  [ROLES]: "roles",
  [APPS]: "apps",

  [OBJECT_STORAGE]: "object-storage",

  [SPACE_PICKER]: "space-picker",
  [SPACE_SETTINGS]: "spaces",
  [SPACE_GENERAL]: "general",
  [HIGH_FREQ_DATA]: "hfd",
  [VARIABLES_STORAGE]: "variables-storage",
  [HOME_PAGE]: "homepage",
  [AU_MANAGEMENT]: "au-management",

  [ADMIN]: "admin",
  [ADMIN_USERS]: "users",
  [ADMIN_SPACES]: "spaces"
}

export const endpointToRoute = Object.entries(endpoints).reduce((acc, [key, page]) => ({...acc, [page]: key}), {})

export const getEndpoint = routeName => endpoints[routeName]
// 3) add your new endpoint name to the nav tree
//    if adding subpages, make it an object with the children as it's props
const nav = {
  [ROOT]: {
    LOGIN,
    LOGOUT,
    AUTHENTICATE,
    SPACE_PICKER,
    DOCS,
    SUPPORT,
    [ADMIN]:{
      ADMIN_USERS,
      ADMIN_SPACES
    },
    [APP]: {
      LINK_ALTAIR_ONE,
      PROPERTIES_HISTORY_CHART,
      USAGE_HISTORY_CHART,
      HOME_PAGE,
      SETTINGS,
      DOCS_POLICY_RESOURCES,
      [ANYTHING_DB]: {
        CATEGORIES,
        MODELS,
        EVERY_THING,
        OLD_EVERY_THING,
        CUSTOM_QUERIES
      },
      [EDGE_OPS]: {
        CLUSTER_MANAGEMENT: CLUSTER_MANAGEMENT_OLD,
        CLUSTER_MANAGEMENT_BETA: CLUSTER_MANAGEMENT,
        RESOURCES,
        EDGE_APPS,
        EDGE_PROVISIONINGS,
        ECP_IMAGES,
        DISTRIBUTIONS,
        ROLLOUTS
      },
      PACKAGES,
      [SERVERLESS]: {
        FUNCTIONS,
        TRIGGERS
      },
      STREAMS,
      DATA_VIZ,
      [ACCESS_CONTROL]: {
        USERS,
        ROLES,
        APPS
      },
      LABELS,
      OBJECT_STORAGE,
      [SPACE_SETTINGS]: {
        SPACE_GENERAL,
        HIGH_FREQ_DATA,
        VARIABLES_STORAGE,
        AU_MANAGEMENT
      }
    },
  }
}

// add full routing of above below.
// this will allow the routing to remain rather dynamic incase changes are requred.
const makeRoutes = (node, key, url="") => {
  let routes = {}
  let nextUrl
  // add current url+key, if a url
  if (key) {
    // if url is just '/', ignore and just return '/endpoint'
    nextUrl = `${url.length === 1 ? "" : url}/${endpoints[key] || ""}`
    routes[key] = nextUrl
  }
  // go deeper if node is obj
  if (typeof node === "object") {
    const children = Object.entries(node)
    routes = children.reduce((routes, [key, node]) => ({
      ...routes,
      ...makeRoutes(node, key, nextUrl)
    }), routes)
  }
  return routes
}

export const routes = makeRoutes(nav)

//Convert uriParams object: {a: true, b: false}
//to request uri format: ?a=true&b=false
export function formatUriParams(uriParams={}) {
  const params = new URLSearchParams()
  Object.entries(uriParams).forEach(([key, value]) => {
    if (value instanceof Array) {
      value.forEach(value => params.append(key, value.toString()))
    }
    else {
      params.append(key, value.toString())
    }
  })
  return "?" + params.toString()
}

export const getPageByPathname = pathname => {
  return findKey(routes, r => r === pathname)
}
