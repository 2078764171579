import { PROXY_URL } from "constants/config"

import { servFeatures } from "./config"

// Initialize WebSocket
const socket = new WebSocket(PROXY_URL + "/lsp", "ws")
// const RECONNECT_INTERVAL = 3000 // 3 seconds delay for reconnecting

socket.onopen = () => {
  console.log("WebSocket connection established")
}

socket.onmessage = (event) => {
  console.log("Message received:", event.data)
  // Handle incoming messages
}

socket.onerror = (error) => {
  console.error("WebSocket error:", error)
}

socket.onclose = () => {
  console.log("WebSocket connection closed, attempting to reconnect...")
  // Reconnect after a delay
  // setTimeout(initializeWebSocket, RECONNECT_INTERVAL) //It fails to reconnect for the subsequent times. It happens with the authorization in the proxy and without it.
}

// Exporting the servers array dynamically to ensure it always has the fresh WebSocket instance
export let servers = [
  {
    module: () => import("ace-linters/build/language-client"),
    modes: "python",
    type: "socket",
    socket: socket,
    features: servFeatures,
    initializationOptions: {
      pylsp: {
        plugins: {
          autopep8: { enabled: false },
          flake8: { enabled: false },
          mccabe: { enabled: false },
          pylint: { enabled: false },
          pycodestyle: { enabled: true, ignore: [ "W", "E111", "E112", "E113", "E114", "E116", "E117", "E121", "E122", "E222", "E225", "E241", "E302", "E303", "E501" ]},
          pyflakes: { enabled: true },
          yapf: { enabled: true },
          rope: { enabled: true },
          jedi_completion: {
            enabled: true,
            cache_for: ["pandas", "numpy", "matplotlib", "scipy", "sympy", "requests", "iots"]
          }
        }
      },
    },
  },
]
