import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import Spinner from "components/common/Spinner"
import { getUsageHistories } from "reduxModules/auManagement"

import UsageHistoryChartHistogram from "./UsageHistoryChartHistogram"
import UsageHistoryChartTitle from "./UsageHistoryChartTitle"

const mapState = (state) => ({
  name: new URLSearchParams(state.router.location.search).get("name"),
  usageHistories: state.auManagement.usageHistories || [],
})

const UsageHistoryChart = ({ name, usageHistories, getUsageHistories }) => {
  const [usageHistoriesLoading, setUsageHistoriesLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setUsageHistoriesLoading(true)
      await getUsageHistories()
      setUsageHistoriesLoading(false)
    })()
  }, [])

  console.log(usageHistories)
  if (!usageHistories.length) {
    return <Spinner />
  }

  return (
    <div style={styles.container}>
      <UsageHistoryChartTitle name={name} />

      <div style={styles.histogramLegendContainer}>
        <UsageHistoryChartHistogram
          name={name}
          usageHistories={usageHistories}
          usageHistoriesLoading={usageHistoriesLoading}
        />
      </div>
    </div>
  )
}

const styles = {
  histogramLegendContainer: {
    display: "flex",
    alignItems: "stretch",
    flexGrow: 1,
    gap: 12,
  },
  container: {
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    width: "100%",
    gap: 12,
    padding: 12,
  },
}

export default connect(mapState, { getUsageHistories })(UsageHistoryChart)
