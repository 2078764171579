import React, { useEffect, useRef } from "react"

import { colors } from "assets/stylesheets/common"
import Spinner from "components/common/Spinner"
import UnityHistogram from "components/unity/UnityHistogram"

import createEchartOptions from "./utils/createEchartOptions"
import prepareHistoryDataForChart from "./utils/prepareHistoryDataForChart"

const UsageHistoryChartHistogram = ({
  usageHistoriesLoading = false,
  usageHistories = [],
  name = "",
}) => {
  const chartRef = useRef(null)
  const chartValues = prepareHistoryDataForChart(usageHistories, name)

  useEffect(() => {
    if (chartRef.current) {
      const currentOption = chartRef.current.getOption()
      if (!currentOption) {
        chartRef.current.setOption(
          createEchartOptions(chartValues, colors["blue-01-825"])
        )
      } else {
        chartRef.current.setOption(
          { ...currentOption, series: chartValues },
          true
        )
      }
    }
  }, [chartValues])

  if (usageHistories.length === 0) {
    return <div style={{ flexGrow: 1 }} />
  }

  return (
    <div style={styles.chartWrapper} id={`${name}-chart`}>
      {usageHistoriesLoading && (
        <div style={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
      <UnityHistogram chartRef={chartRef} />
    </div>
  )
}

const styles = {
  spinnerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1,
  },
  chartWrapper: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "stretch",
    flexGrow: 1,
    overflow: "hidden",
    marginTop: -20,
  },
}
export default UsageHistoryChartHistogram
