import { cloneDeep } from "lodash"
import React from "react"
import { Field } from "react-final-form"
import { connect } from "react-redux"

import DropdownInputNew from "components/common/Inputs/DropdownInput2"

export const variableTypesLabels = {
  "default": "Default",
  "secret": "Secret",
  "app_credentials": "App Credentials",
  "thing_oauth2_credentials": "Thing OAuth2 Credentials",
  "thing_mqtt_credentials": "Thing MQTT Credentials",
  "category_mqtt_credentials": "Category MQTT Credentials",
  "label_mqtt_credentials": "Label MQTT Credentials",
  "hfd_mqtt_credentials": "HFD MQTT Credentials",
}

const variableTypesSchemas = {
  credentials: {
    "type": "object",
    "properties": {
      "client_id": {
        "type": "string",
        "minLength": 1,
        "maxLength": 100
      },
      "client_secret": {
        "type": "string",
        "minLength": 1,
        "maxLength": 100
      }
    },
    "required": [
      "client_id",
      "client_secret"
    ],
    "additionalProperties": false
  },
  mqtt_credentials: {
    "type": "object",
    "properties": {
      "username": {
        "type": "string",
        "minLength": 1,
        "maxLength": 100
      },
      "password": {
        "type": "string",
        "minLength": 1,
        "maxLength": 100
      }
    },
    "required": [
      "username",
      "password"
    ],
    "additionalProperties": false
  }
}

const variableTypesSchemasValues = {
  credentials: {
    "client_id": "",
    "client_secret": ""
  },
  mqtt_credentials: {
    "username": "",
    "password": ""
  }
}

const variableTypesOptions = [
  {
    label: "Editable",
    options:[
      {
        label: variableTypesLabels["default"],
        id: "default"
      },
      {
        label: variableTypesLabels["secret"],
        id: "secret"
      }
    ]
  },
  {
    label: "Non-Editable",
    options: [
      {
        label: variableTypesLabels["app_credentials"],
        id: "app_credentials"
      },
      {
        label: variableTypesLabels["thing_oauth2_credentials"],
        id: "thing_oauth2_credentials"
      },
      {
        label: variableTypesLabels["thing_mqtt_credentials"],
        id: "thing_mqtt_credentials"
      },
      {
        label: variableTypesLabels["category_mqtt_credentials"],
        id: "category_mqtt_credentials"
      },
      {
        label: variableTypesLabels["label_mqtt_credentials"],
        id: "label_mqtt_credentials"
      },
      {
        label: variableTypesLabels["hfd_mqtt_credentials"],
        id: "hfd_mqtt_credentials"
      }
    ]
  }
]

const VariableType = ({form = {}}) => {
  return (
    <Field
      name="type"
      component={DropdownInputNew}
      options={variableTypesOptions}
      containerStyle={{width: "100%"}}
      onValueChange={type => {
        form.change("type", type)

        switch (type) {
        case "app_credentials":
        case "thing_oauth2_credentials":
          form.change(
            "schema",
            cloneDeep(variableTypesSchemas.credentials)
          )
          form.change(
            "value",
            cloneDeep(variableTypesSchemasValues.credentials)
          )
          break
        case "thing_mqtt_credentials":
        case "category_mqtt_credentials":
        case "label_mqtt_credentials":
        case "hfd_mqtt_credentials":
          form.change(
            "schema",
            cloneDeep(variableTypesSchemas.mqtt_credentials)
          )
          form.change(
            "value",
            cloneDeep(variableTypesSchemasValues.mqtt_credentials))
          break
        default:
          form.change("schema", { type: "string" })
          form.change("value", "")
          break
        }
      }}
      label='Type'
      id="variable-description-field"
      placeholder='Select a variable type'
      hideError
    />
  )
}

export default connect(null)(VariableType)