import { omit } from "lodash"

import { get, post } from "./api"
import { getSpaceId } from "./auth"


export async function getInfoRequest() {
  const { data } = await get("/boundaries/info", null, { rootRequest: true})
  return data
}

export async function getMaxRequest() {
  const { data } = await get(`/boundaries/${getSpaceId()}/max`, null, { rootRequest: true})
  return data
}

export async function getStatusRequest(space) {
  const { data } = await get(`/boundaries/${space}/status`, null, { rootRequest: true})
  return data
}

export async function getSpacesStatusRequest(spaceIds) {
  const params = {
    "space[]": spaceIds,
    "limit": 1000
  }
  const { data } = await get("/boundaries/spaces-status", params, { rootRequest: true })
  return data
}

export async function getUnitsRequest() {
  const { data } = await get(`/boundaries/${getSpaceId()}/units`, null, { rootRequest: true})
  return data
}

export async function getUsageRequest() {
  const { data } = await get(`/boundaries/${getSpaceId()}/usage`, null, { rootRequest: true})
  return data
}

export async function getLastUsageRequest() {
  const { data } = await get(`/boundaries/${getSpaceId()}/last-usage`, null, { rootRequest: true})
  return data
}

export async function getUsageHistoriesRequest() {
  const { data } = await get(`/boundaries/${getSpaceId()}/usage-histories`, null, { rootRequest: true})
  return data
}

export async function getUsageHistoryRequest(historyId) {
  const { data } = await get(`/boundaries/${getSpaceId()}/usage-histories/${historyId}`, null, { rootRequest: true})
  return data
}

export async function postCalculatorRequest(body) {
  const { data } = await post(`/boundaries/${getSpaceId()}/calculator`, body, { rootRequest: true})
  return data
}

export function getTierUnits(amount=0, config={}, id="") {
  const { base=0, step=1, units=0 } = config
  if (!amount || amount <= base) return 0
  else {
    if (step > base && id !== "function_memory") return Math.ceil(amount / step) * units
    return Math.ceil((amount - base) / step) * units
  }
}

export function getMessagesUnits(amount=0, config=[]) {
  if (amount) {
    const packageIndex = config.findIndex(c => amount <= c[1])
    if (config[packageIndex]) return { units: config[packageIndex][2], tier: config[packageIndex][0] }
  }
  return { units: 0, tier: 0 }
}

export function getTotalUnits(unitsObject={}) {
  return Object.values(omit(unitsObject, "total")).reduce((acc, current) => acc + current, 0)
}

export function parseMemory(memoryState) {
  return memoryState ? 500 : 200
}

export function formatMemory(memoryValue) {
  return memoryValue === 500
}

export const validateUserInput = (value, allValues, config={}, id, usage ) => {
  const { messages_day, tiers } = config
  const { base, max } = tiers?.[allValues.tier]?.[id] || {}
  if (value && isNaN(value)) return "Must be a number"
  else if (value < base) return `Cannot be less than ${base}`
  else if (value < 0) return "Must be positive"
  else if (max && value > max) {
    return `Cannot be larger than ${max}`
  }
  else if (messages_day && id === "messages_day") {
    const maxMessagesDay = Math.max(...messages_day.map(m => m[1]))
    if(value > maxMessagesDay) return `Cannot be larger than ${maxMessagesDay}`
  }
  else if (value < usage[id]) return "Cannot be less than usage"
}
