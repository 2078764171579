import styled from "styled-components"

const UnityDropdownCheckbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid #A2AAAD;
    background-color: ${props => props.disabled ? "#F4F4F4" : "white"};
`

export default UnityDropdownCheckbox
