import { UnityTypography } from "@bit/smartworks.unity-react.unity-core-react"
import { AceLanguageClient } from "ace-linters/build/ace-language-client"
import React, { Component, createRef } from "react"
import AceEditor from "react-ace"
// NOTE: webpack-resolver prevents this component from being used in apps without webpack
// import "ace-builds/webpack-resolver.js"
import "ace-builds/src-noconflict/mode-json5"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/mode-python"
import "ace-builds/src-noconflict/mode-golang"
import "ace-builds/src-noconflict/mode-sql"
import "ace-builds/src-noconflict/mode-dockerfile"
import "ace-builds/src-noconflict/mode-markdown"
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/mode-yaml"
import "ace-builds/src-noconflict/theme-textmate"
import "ace-builds/src-noconflict/ext-searchbox"
import "ace-builds/src-noconflict/ext-language_tools"
import ResizeObserver from "resize-observer-polyfill"
import { debounce } from "throttle-debounce"
import "./UnityCodeEditor.css"

import { servers } from "./servers"

class UnityCodeEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: ""
    }

    this.editorContainerRef = createRef()
    this.editorRef = createRef()

    this.handleChange = this.handleChange.bind(this)
    this.resizeEditor = debounce(500, false, this.resizeEditor.bind(this))
  }

  componentDidMount() {
    this.handleChange(this.props.value)
    // Set up resize observer to allow code editor height to be dynamic
    this.resizeObserver = new ResizeObserver(this.resizeEditor)
    this.resizeObserver.observe(this.editorContainerRef.current)
    if (this.editorContainerRef.current) {
      const editor = this.editorRef.current.editor
      editor.getSession().setUseWorker(true)
      const languageProvider = AceLanguageClient.for(servers)
      languageProvider.registerEditor(editor)

      const options = this.editorRef.current.options ?? {}
      languageProvider.setSessionOptions(editor.session, options)
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver && this.editorContainerRef.current) {
      this.resizeObserver.unobserve(this.editorContainerRef.current)
    }
  }

  handleChange(newValue) {
    const { validation, onChange } = this.props
    let error = ""
    if (typeof validation === "function") {
      error = validation(newValue)
    }
    this.setState({ error })
    onChange(newValue, error)
  }

  getValidationMessage(message) {
    const { embedded } = this.props
    return (
      <div className={`code-editor-error ${embedded ? "embedded" : ""}`} id="code-editor-error-text">
        <UnityTypography color="medium" style={{ "--font-color-medium": "var(--internal-validation-color)" }} size="paragraph">
          {message}
        </UnityTypography>
      </div>
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { value: nextValue } = nextProps
    const { error: nextError } = nextState
    const { value } = this.props
    const { error } = this.state

    return !((nextError === error) && (nextValue === value))
  }

  resizeEditor() {
    if (this.editorRef.current && this.editorRef.current.editor) {
      this.editorRef.current.editor.resize()
    }
  }

  render() {
    const {
      id,
      value,
      mode,
      label,
      dirty,
      minLines,
      maxLines,
      error: errorText = "",
      readOnly = false,
      highlightActiveLine = true,
      showLineNumbers = true,
      tabSize = 2,
      embedded = false
    } = this.props

    const { error = "" } = this.state

    const editorWrapperClass = (!(errorText || error) && !embedded) ? "editor-wrapper padded" : "editor-wrapper"
    const dirtyGutterClass = embedded ? "dirty-gutter embedded" : "dirty-gutter"
    const editorContainerClass = embedded ? "editor-container embedded" : "editor-container"

    const editorStyle = { width: "100%" }

    // This allows having the editor fill the parent container if minLines and maxLines are not passed into the component
    const lineProps = {}
    if (minLines) lineProps.minLines = minLines
    if (maxLines) lineProps.maxLines = maxLines
    if (!minLines && !maxLines) editorStyle.flex = 1

    return (
      <div id={id} className={editorWrapperClass} ref={this.editorContainerRef}>
        {label && !embedded &&
              <div className="code-editor-label">
                <UnityTypography color="medium" size="paragraph">
                  {label}
                </UnityTypography>
              </div>
        }

        {!!dirty && <div className={dirtyGutterClass} />}

        <div className={editorContainerClass}>
          <AceEditor
            ref={this.editorRef}
            value={value}
            style={editorStyle}
            theme={"textmate"}
            mode={mode === "json" ? "json5" : mode}
            editorProps={{ $blockScrolling: true }}
            onChange={this.handleChange}
            readOnly={readOnly}
            highlightActiveLine={highlightActiveLine}
            setOptions={{
              showLineNumbers,
              enableLiveAutocompletion: true,
            }}
            tabSize={tabSize}
            wrapEnabled
            {...lineProps}
          />
        </div>
        {(errorText || error) && this.getValidationMessage(errorText || error)
        }
      </div>
    )
  }
}

export default UnityCodeEditor