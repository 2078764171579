import * as echarts from "echarts"
import { omit } from "lodash"
import moment from "moment"
import React, {Component, createRef} from "react"
import ResizeObserver from "resize-observer-polyfill"
import {debounce} from "throttle-debounce"

/**
 * @typedef {import("echarts").EChartsOption} EChartsOption
 * @typedef {import("echarts").EChartsType} EChartsType
 */

/**
 * @typedef HistogramData
 * @property {string} timestamp - timestamp of the data point
 * @property {number} value - value of the data point
 */

/**
 * @typedef formatSeriesDataReturn
 * @type {object[]}
 * @property {string} name - timestamp
 * @property {string} timestamp - timestamp
 * @property {number[]} value - array of timestamp and value
 */

/**
 * @param {HistogramData[]} data
 * @returns {formatSeriesDataReturn} - array of objects with name, timestamp, and value properties
 */
function formatSeriesData(data) {
  return data.map(({timestamp, value}) => ({
    name: timestamp,
    timestamp,
    value: [timestamp, value]
  }))
}

/**
 * @param {formatSeriesDataReturn} params
 * @returns {string} - formatted tooltip text
 */
const formatTooltip = (params) => {
  return `${moment(params["value"][0]).fromNow()}: ${params["value"][1]}`
}



/**
 * @typedef {object} UnityHistogramProps
 * @property {TooltipFormatterCallback} tooltipFormatter - function to format the tooltip text.
 * @property {HistogramData[]} data - array of timeseries data, each with a timestamp and value. Assuming timestamps are unique.
 * @property {string} barColor - color value of bar segments. Defaults to light blue.
 * @property {EChartsOption} options - optional EChart options for complete control over chart conviguration
 * @property {CSSProperties} style - style object
 * @property {object} chartRef - optional ref object to access the chart instance
 * @extends {Component<UnityHistogramProps>}
 */
export default class UnityHistogram extends Component {
  /** @type {React.RefObject<HTMLDivElement|null>} */
  histRef = createRef()
  /** @type {ResizeObserver|undefined} */
  resizeObserver
  /** @type {EChartsType|undefined} */
  chart

  componentDidMount() {
    this.chart = echarts.init(this.histRef.current)
    if (typeof this.props.chartRef === "object" ) {
      this.props.chartRef.current = this.chart
    } else {
      const options = this.createChartOptions()
      this.chart.setOption(options)
    }

    this.resizeObserver = new ResizeObserver(this.resizeChart)
    this.resizeObserver?.observe(this.histRef.current)
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.chartRef === "object" ) {
      return
    }
    
    const {data=[], options={}} = this.props
    if ((prevProps?.data !== data && prevProps?.options !== options) && !!this.chart) {
      const newOptions = this.createChartOptions()
      this.chart.setOption(newOptions)
    }
  }

  componentWillUnmount() {
    this.resizeObserver?.unobserve(this.histRef.current)
    this.resizeChart.cancel()
  }

  resizeChart = debounce(500, false, () => {
    this.chart?.resize()
  })

  /**
   * @returns {EChartsOption}
   */
  createChartOptions = () => {
    const {
      data=[],
      barColor="#92b4dd",
      tooltipFormatter=formatTooltip,
      options={}
    } = this.props

    /** @type {EChartsOption} */
    const defaultOptions = {
      title: {
      },
      grid: {
        show: true,
        containLabel: false,
        top: 0,
        left: 25,
        right: 25,
        bottom: 20,
        borderColor: "#454545",
        borderWidth: 1,
      },
      tooltip: {
        transitionDuration: 0.2,
        formatter: tooltipFormatter,
        textStyle: {
          fontFamily: "Noto",
          fontSize: 12
        }
      },
      xAxis: {
        show:true,
        type: "time",
        splitLine: {
          show: false
        },
        axisLabel: {
          fontSize: 10,
          fontFamily: "Noto"
        },
        splitNumber: 3
      },
      yAxis: {
        show: false,
        type: "value"
      },
      series: [{
        name: "bar",
        type: "bar",
        data: formatSeriesData(data)
      }],
      animation: true,
      color: barColor ? [barColor] : undefined,
      animationDuration: 200
    }

    return {...defaultOptions, ...options}
  }

  render() {
    const rest = omit(this.props, ["data", "options", "tooltipFormatter", "barColor", "style", "chartRef"])

    return (
      <div
        style={{
          ...styles.container,
          ...this.props.style
        }}
        ref={this.histRef}
        {...rest}
      >
      </div>
    )
  }
}

const styles = {
  container: {
    minWidth: 0,
    flex: "1 1 auto"
  }
}