import { colors } from "assets/stylesheets/common"

const expandIconPath =
  "M4 4v8h2v-6h6v-2h-8z, M26 4h-6v2h6v6h2v-8h-2z, M6 20h-2v8h8v-2h-6v-6z, M26 26h-6v2h8v-8h-2v6z"
const refreshIconPath =
  "path://M16.5 9.051v-3.051l-6.5 4 6.5 4v-2.949c3.623 0.262 6.5 3.261 6.5 6.949 0 3.859-3.141 7-7 7s-7-3.141-7-7c0-0.553-0.447-1-1-1s-1 0.447-1 1c0 4.963 4.037 9 9 9s9-4.037 9-9c0-4.792-3.774-8.684-8.5-8.949z"

const getPadding = (v, p) => {
  const totalLength = v.max - v.min
  const padding = (totalLength * p) / 100
  return padding
}

const iconStyle = {
  iconStyle: {
    color: colors.charcoal,
    borderWidth: 0,
  },
  emphasis: {
    iconStyle: {
      color: colors.deepBlue,
      borderWidth: 0,
    },
  },
}

const createEchartOptions = (values, dataZoomColor) => ({
  title: {},
  grid: {
    show: true,
    containLabel: true,
    top: 22, // We need to add some padding to the top to make the yAxis labels visible
    left: 0,
    right: 4,
    bottom: 40,
    borderColor: colors.gray,
    borderWidth: 1,
  },
  toolbox: {
    itemSize: 12,
    feature: {
      dataZoom: {
        yAxisIndex: "none",
        title: {
          zoom: "Marquee zoom",
          back: "",
        },
        icon: {
          zoom: expandIconPath,
          back: "blank",
        },
        ...iconStyle,
      },
      restore: {
        title: "Reset zoom",
        icon: refreshIconPath,
        ...iconStyle,
      },
    },
  },
  dataZoom: [
    {
      type: "slider",
      left: 2,
      right: 2,
      bottom: 8,
      backgroundColor: colors.white,
      dataBackground: {
        areaStyle: {
          color: colors.lightGray1,
          opacity: 1,
        },
      },
      borderColor: colors.lightGray1,
      fillerColor: "rgba(0,0,0,0.05)",
      moveHandleStyle: {
        color: colors.lightGray1,
        opacity: 1,
      },
      emphasis: {
        moveHandleStyle: {
          color: colors.gray,
        },
      },
      selectedDataBackground: {
        areaStyle: {
          color: dataZoomColor,
          opacity: 1,
        },
      },
    },
    {
      type: "inside",
    },
  ],
  yAxis: {
    show: true,
    type: "value",
    axisLine: {
      lineStyle: {
        color: colors.lightGray1,
      },
    },
    axisLabel: {
      color: colors.charcoal,
    },
  },
  xAxis: {
    type: "time",
    show: true,
    splitLine: {
      show: false,
    },
    min: (v) => v.min - getPadding(v, 2),
    max: (v) => v.max + getPadding(v, 2),
    minInterval: 0,
    axisLine: {
      lineStyle: {
        color: colors.lightGray1,
      },
    },
    axisLabel: {
      hideOverlap: true,
      formatter: (value) => {
        return new Date(value).toISOString().split(".")[0]
      },
      fontSize: 10,
      fontFamily: "Noto",
      color: colors.charcoal,
    },
    splitNumber: 3,
  },
  tooltip: {
    formatter: (params) => `
        <p style="color: ${colors.text1}">${params.seriesName}: <b>${
    params.data[1]
  }</b></p>
        <p style="font-size: 10px;">${
  new Date(params.data[0]).toISOString().split(".")[0]
  }</p>`,
    borderColor: "white",
    extraCssText:
      "box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px 2px; border-radius: 0px; padding-top: 0px",
    textStyle: {
      fontFamily: "Noto",
      fontSize: 12,
    },
  },
  color: colors.deepBlue,
  textStyle: {
    fontFamily: "Noto",
    fontSize: 8,
  },
  series: values,
})

export default createEchartOptions
