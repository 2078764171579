import { Buffer } from "buffer"
import stringify from "json-stringify-pretty-compact"
import JSON5 from "json5"
import moment from "moment"
import { useEffect } from "react"

// likely use this when saving the object from the cloud to avoid calling the
// stringify more than once per initial values
export function prettyJSON(json) {
  return stringify(json, { maxLength: 0, indent: "\t" })
}

export function prettyJSONCompact(json) {
  return stringify(json, { maxLength: 0 })
}

// checks if string is actually a JSON object
// later can expand to check for individual keys, formatting and/or required
export function validateJSON(val) {
  try {
    const parsedJson = JSON.parse(val)
    return { value: parsedJson }
  }
  catch(error) {
    return { error: error.toString() }
  }
}

export const capitalize = str => str.replace(/^\w/, c => c.toUpperCase())

export const makeKeyedHashFromListOfObjects = (list, hashKeyString) => {
  if (!Array.isArray(list)) {
    console.warn("CAUGHT ERROR: hash maker given invalid list")
    return []
  }

  return list.reduce((hashObject={}, listObject={}) => {
    if (!listObject[hashKeyString]) return hashObject
    hashObject[listObject[hashKeyString]] = listObject
    return hashObject
  }, {})
}

// returns object of array of objects. Key will be based on 'key'. Can also include all labels.
// input:       data - array of objects to turn into a lib
//               key - string for the key to save each datum under, defaults to 'id', else index
//            format - function to process the datum, saved as the value
//         getLabels - bool to process 'label' keys out of datum, changes output format
// output: getLabels = false: data as object lib, keys matching value of each datum's 'key'
//         getLabels = true:  object with libaray and labels keys, former being output of above,
//                            latter being lib of all labels attached to each datum
export const makeLib = ({ data = [], key: definedKey = "id", format }) => {
  if (!Array.isArray(data)) {
    console.warn("makeLib given data of non-Array format")
    return {}
  }
  return data.reduce((lib, datum, i) => {
    const key = typeof datum === "object" ? datum[definedKey] || i : datum
    return {
      ...lib,
      [key]: format instanceof Function ? format(datum) : datum,
    }
  }, {})
}

const byteLib = {
  0: "",
  1: "K",
  2: "M",
  3: "G",
}

export const quantify = (amount=0) => {
  let size = 0
  while (amount >= 1000 && size < 3) {
    amount /= 1000
    size++
  }
  return `${amount} ${byteLib[size]}`
}

export const decimalToPercent = value => `${Math.floor(value*100)}%`

export const removeFromArray = (source, lib) => {
  return source.filter(data => !lib[data.id])
}

/**
 * Parse an array-like string or comma delimited list to an array
 * @param {string} value - a string including comma-separated elements
 * @return {?string[]} array of elements
 */
export const parseArray = value => {
  // remove chars []" and parse to array
  if (Array.isArray(value)) return value
  if (value)
    return value
      .replace(/"|(?:^\[)|(\]$)/g, "")
      .split(",")
      .map((v) => v.trim())
}

/**
 * Format array into an array-like string
 * @param {?string[]} value - an array of strings
 * @return {string} a comma-deliniated string list with brackets
 */
export const formatArray = value => {
  if (value) return `[${value.toString()}]`
}

/**
 * Format string into valid object
 * @param {object} value
 */
export const parseObject = (value) => {
  if (typeof value === "object") return value
  return JSON5.parse(value)
}

export const parseValue = (value, type) => {
  // parse string to corresponding type
  switch (type) {
  case "enum":
  case "array":
    return parseArray(value)
  case "number":
  case "integer": {
    const numberized = Number(value)
    return isNaN(numberized) ? value : numberized
  }
  case "object":
    return parseObject(value)
  case "boolean":
    if (typeof value === "string") {
      if (value === "true") return true
      if (value === "false") return false
    }
    return value
  case "null": return null
  case "string":
  default:
    return value
  }
}


/**
 * Convert array buffer to string
 */
export const abToStr = (ab) => {
  const decoder = new TextDecoder("utf-8")
  return decoder.decode(ab)
}


/**
 * Convert array buffer to JSON object
 */
export const abToJson = ab => {
  return JSON.parse(abToStr(ab))
}

/**
 * Convert JSON object to array buffer
 */
export const jsonToArrayBuffer = json => {
  const str = JSON.stringify(json, null, 0)
  let ret = new Uint8Array(str.length)
  for (let i = 0; i < str.length; i++) {
    ret[i] = str.charCodeAt(i)
  }
  return ret
}

/**
 * Functions for endcoding and decoding a string to the specified encoding
 */

export const encodeString = (value, encoding) => Buffer.from(value).toString(encoding)

export const decodeString = (value, encoding) => Buffer.from(value, encoding).toString()

/**
 * Check if an object is empty
 */
export function isEmpty(object) {
  return Object.keys(object).length === 0 && object.constructor === Object
}

/**
 * Return color brightness from hex string using formula from https://www.w3.org/TR/AERT/#color-contrast
 * @param {string} color Full hex string
 */
export function getColorBrightness(color) {
  const { r, g, b } = hexToRgb(color)
  return ( 0.299 * r + 0.587 * g + 0.114 * b)/255
}

/**
 * Darken a hex color by a specified amount
 * @param {string} color Full hex string
 * @param {number} amount Amount to darken by (0-255)
 * @return {string} Darkened hex color
 */
export const darkenColor = (color, amount) => {
  const { r, g, b } = hexToRgb(color)
  const darkenedColor = `#${[r, g, b].map(c => Math.round(Math.max(0, c - amount)).toString(16).padStart(2, "0")).join("")}`
  return darkenedColor
}

export const getDarkenedColor = (color) => {
  const colorBrightness = getColorBrightness(color)
  const darkenAmmount = (colorBrightness > 0.5 ? 0.2 : 0.1) * 255
  return darkenColor(color, darkenAmmount)
}

export function hexToRgb(hex) {
  let hexString
  if (hex.length === 4) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    hexString = hex.slice(1).split("").map(s => s + s).join("")
  } else if (hex.length === 7) {
    hexString = hex.slice(1)
  }
  const hexNumber = parseInt(hexString, 16)

  return {
    r: (hexNumber >> 16) & 255,
    g: (hexNumber >> 8) & 255,
    b: hexNumber & 255
  }
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)
    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handler])
}
export function clickedOutside(elementId, event) {
  const { target, path: eventPath} = event
  const path = eventPath || (event.composedPath && event.composedPath())
  if (!path) return // IE will not have a path here
  if (!target || !Array.isArray(path)) return

  // if clicked outside the element, close it
  const el = document.getElementById(elementId)
  return !path.some(comp => comp === el || comp.id === el.id)
}

export function getTagStyles(color) {

  const brightness = getColorBrightness(color)
  const lightColor = brightness >= 0.5
  return {
    "--tag-color": color,
    "--tag-text-color": lightColor ? "black" : "white",
    "--tag-border": lightColor ? "1px solid var(--dark-gray-color)" : "none",
  }
}

export class MockedError extends Error {
  constructor() {
    super()
    this.message = "Mocked request error"
    this.name = "MockedError"
  }
}

// function to zip two arrays together
// new array is used as the truth, while unchanged data from old is preserved
// intended to be used by generic actions, and thus returns a function
export const zipLists =
  (oldList, key = "id") =>
    (newList) => {
    // iterate over newList
      const zippedList = newList.map((newItem) => {
      // find item in old list
        const oldItem = oldList.find((oldItem) => oldItem[key] === newItem[key])
        // make new item from
        return { ...oldItem, ...newItem }
      })
      return zippedList
    }


// Using this method instead of Number.toFixed to not show decimal for whole numbers
export const roundTenth = (num) => {
  return Math.round(num * 10) / 10
}


export const copyToClipboard = value => {
  const el = document.createElement("textarea")
  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
}

/** readValueInNestedObjectsByArrayPath
 * @description Function for reading a value inside a deeply-nested object (of unknown structure) using a keyPath
 * @param {object} options
 * @param {object} options.originalObject - object that contains n nested objects
 * @param {string[]} options.keyPath - array of length n; each element being a key to the next nested object; the last, the key for the value we want to read
 */
export const readValueInNestedObjectsByArrayPath = ({
  object={},
  keyPath=[]
}) => {
  // create a pointer, which we will assign the pointer location of the val we want to read
  let pointer = object

  // recurse down the object, moving the pointer to the next level down each time
  for (const key of keyPath) {
    pointer = pointer[key]
    if (!pointer) return // return undefined at any point the key is invalid (not in the object)
  }

  // return the value of the last key in the keypath
  return pointer
}

/** updateObjectOfNestedObjectsByArrayPath
 * @description Function for updating a deeply-nested value in an object (also works for adding new keys to nest objects)
 * @param {object} options
 * @param {object} options.originalObject - object that contains any number of nested objects
 * @param {*} options.newValue - value we want to set. pass undefined to delete the value instead.
 * @param {string[]} options.keyPath - array of length n; each element being a key to the next nested object; the last, the key for the value we want to change
 */
export const updateObjectOfNestedObjectsByArrayPath = ({
  object={},
  newValue,
  keyPath=[]
}) => {
  // create mutable values
  let mutableObject = { ...object }
  try {
    mutableObject = JSON.parse(JSON.stringify(mutableObject))
  } catch(err) {
    console.warn("Failed to make deep copy of object: ", object)
  }
  const parentPath = [...keyPath]

  const valueKey = parentPath.pop()
  // create a pointer, which we will assign the pointer location of the val we want to change
  let pointer = mutableObject // point to the mutable object
  parentPath.forEach(key => {
    if (!pointer[key]) pointer[key] = {} // if key doesn't exist, create it
    pointer = pointer[key] // move the pointer down one level (no change to object yet)
    // TODO: Error handling if object structure is not as expected
  })
  pointer[valueKey] = newValue // now that the pointer is where we want it, change that key to the value
  return mutableObject
}

export function getParsedValue(unparsedValue, type) {
  let value = unparsedValue
  try {
    value = !!unparsedValue && (type === "object" || type === "array") && typeof unparsedValue === "string" ? JSON.parse(unparsedValue) : unparsedValue
  } catch(err) {
    console.warn(err)
    if (type === "object") {
      value = {}
    }
    if (type === "array") {
      value = []
    }
  }
  return value
}

export const getIdFromHref = (href) => href?.match(/[A-Z0-9]{26}$/)?.[0]

// moving this to misc as it's needed here, to avoid cyclical dependency
export const getClusterNumericId = cluster => cluster?.id?.match(/\/things\/(\w+)/)?.[1]

// func to make a shell of an object cluster thing to avoid having to look up the cluster thing entry
export const makeClusterThingShell = clusterId => ({
  uid: getClusterNumericId({id: clusterId})
})

//Test first 100 characters in a string if it is extended ASCII
//Checks if string is binary
export const isASCII = (str) => {
  const slicedStr = str.slice(0,100)
  // eslint-disable-next-line no-control-regex
  return /^[\x00-\xFF]*$/.test(slicedStr)
}

// Processor for taking an array of objects and getting UnityDropdown options from them
// args
//   items: array of objects to get options from
//   idKey: string key to be used for the option ids
//   labelKey: string key to be used for the option labels, defaults to idKey
//   formatLabel: optional function for formatting the label, is given {id, label}
// output
//   array of dropdownOptions objects, { id: string, label: string }
export const makeDropdownOptions = ({items=[], idKey, labelKey=idKey, suffixKey, formatLabel=()=>{}}) => {
  const itemsToProcess = (Array.isArray(items) ? items : [])
  const options = itemsToProcess.map(({ [idKey]: id, [labelKey]: label, [suffixKey]: suffix }) => ({
    id: `${id}`,
    label: formatLabel({id, label, suffix}) || label
  }))
  return options
}

export const validateRegex = value => {
  try {
    new RegExp(value)
    return ""
  }
  catch (e) {
    return "Invalid regular expression"
  }
}

export const isObject = v => {
  return typeof v === "object" && !Array.isArray(v) && v !== null
}

export const createGetInputFieldLabel = (editing) => {
  return (title, required=false) => {
    return `${title}${editing? (required ? " (required)": " (optional)") : ""}`
  }
}

// create batches based on an array of keys
export const createBatches = (keys=[]) => {
  const batchLimit = 50
  const idBatches = keys.reduce((batches, key) => {
    const lastBatch = batches[batches.length - 1] || []

    if (lastBatch.length > 0 && lastBatch.length < batchLimit) {
      //add to current batch
      lastBatch.push(key)
    } else {
      //create new batch
      batches.push([key])
    }
    return batches
  }, [])
  return idBatches
}

export function formatDate(date, withSecs=false) {
  return moment(date)?.format(`MMM D YYYY, h:mm${withSecs ? ":ss" : ""} a`)
}

export const getFilteredTableData = (data, filters, nameToFilter) => {
  if (filters[nameToFilter]?.length > 0) {
    const { value } = filters[nameToFilter][0]
    return data?.filter(r => r[nameToFilter].toLowerCase().includes(value.toLowerCase()))
  } else {
    return data
  }
}