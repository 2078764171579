
import styled from "styled-components"

import UnityDropdownCheckbox from "./UnityDropdownCheckbox"


const UnityDropdownOptionsList = styled.div`
  --dropdown-options-list-max-height-internal: var(--dropdown-options-list-max-height, none);
  --dropdown-highlighted-option-color-internal: var(--dropdown-highlighted-option-color, ${props => props.$multi ? "white" : "#e1f8f7"});
  --dropdown-highlighted-option-hover-color-internal: var(--dropdown-highlighted-option-hover-color, ${props => props.$multi ? "#F4F4F4" : "#bdf0f0"});
  
  
  &&& {
    line-height: initial;
    max-height: 100vh;
    display: flex;
    flex-direction: column;

    .rc-virtual-list {
      display: flex;
      flex-direction: column;
      min-height: 0px;
    }
    .rc-virtual-list-holder[style] {
      max-height: var(--dropdown-options-list-max-height-internal) !important;
    }

    /* Dropdown menu option styles */
    .ant-select-item{
      &.ant-select-item-option {
          border-radius: 0;
          font: NotoSans-Regular;
          color: #3B3B3B;
          padding: 8px 9px;
          min-height: auto;
          transition-duration: 0s;
          line-height: initial;
          
          & .ant-select-item-option-content {
            display: flex;
            width: fit-content;
            /* flex-direction: ${props => props.$multi ? "row-reverse" : "row"}; */
            gap: 4px;
          }

          ${UnityDropdownCheckbox} {
            border-color: #A2AAAD;

            &:hover {
                background-color: #EAF9FA;
            }

            svg {
              fill: white;
            }
          }

          &.ant-select-item-option-selected {
            font-weight: normal;
            background-color: var(--dropdown-highlighted-option-color-internal);

            ${UnityDropdownCheckbox} {
                --dropdown-select-checkbox-color: #2DCCD3;
                --dropdown-select-checkbox-hover-color: #32A6AC;

                border-color: var(--dropdown-select-checkbox-color);
                background-color: var(--dropdown-select-checkbox-color);

              &:hover {
                border-color: var(--dropdown-select-checkbox-hover-color);
                background-color: var(--dropdown-select-checkbox-hover-color);
              }
            }

            &.ant-select-item-option-active {
              background-color: var(--dropdown-highlighted-option-hover-color-internal);
            }
          }

          &.ant-select-item-option-active{ 
              background-color: #F4F4F4;
          }

          &.ant-select-item-option-disabled {
              color: rgb(162, 170, 173);
          }
      }

      /* Style item groups */
      &.ant-select-item-group {
        font-weight: bold;
        padding: 8px 9px;
        color: #3B3B3B;
        border-radius: 0;
      }

      &.ant-select-item-option-grouped + :not(.ant-select-item-option-grouped),
      &.ant-select-item-group:not(:first-child) {
        border-top: 1px solid #DCDCDC;
      }
    }
  }`

export default UnityDropdownOptionsList
