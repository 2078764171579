import React from "react"
import styled from "styled-components"

const HelperText = ({children}) => <p id="helper-text">{children}</p>

const UnityDropdownHelperText = styled(HelperText)`&&& {
    font-size: 10px;
    text-align: center;
}`

export default UnityDropdownHelperText
