import calculator from "components/AUManagement/calculator.json"

const nameTitles = calculator
  .reduce((acc, current) => {
    acc.push(current.children)
    return acc
  }, [])
  .flat()
  .reduce((acc, row) => {
    acc[row.id] = row.title
    return acc
  })

export default nameTitles
