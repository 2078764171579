/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { makeLib } from "utils/misc"
import {
  addMessage,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "utils/notifications"
import {
  addFileRequest,
  addFolderRequest,
  addMultipartFileRequest,
  createLinkRequest,
  deleteFilesRequest,
  deleteFoldersRequest,
  filterDummyFiles,
  getFilesRequest,
  getFilterQueryParams,
  getFoldersRequest,
  getSortQuery,
  updateFolderRequest,
  updateTtlRequest,
} from "utils/objects"
import { getSpace, getTableSort } from "utils/storage"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setFilesAction",
  "setFoldersAction",
  "addFileAction",
  "addFolderAction",
  "updateFileAction",
  "updateFolderAction",
  "removeFilesAction",
  "removeFoldersAction",
  "setFilesPagingAction",
  "setFoldersPagingAction",
  "setUploadProgressAction",
  "setUpdateFetchingUploadAction",
  "updateLinkAction",
]
const {
  setFilesAction,
  setFoldersAction,
  addFileAction,
  addFolderAction,
  updateFileAction,
  updateFolderAction,
  removeFilesAction,
  removeFoldersAction,
  setFilesPagingAction,
  setFoldersPagingAction,
  setUploadProgressAction,
  setUpdateFetchingUploadAction,
} = makeActions("objects", actionList)

/* === INITIAL STATE === */
const initialState = {
  files: [],
  filesLib: {},
  folders: [],
  foldersLib: {},
  filesPaging: { previous_cursor: "", next_cursor: "" },
  foldersPaging: { previous_cursor: "", next_cursor: "" },
  uploadProgress: 0,
  fetchingUpload: false
}

/* === Reducer === */
export default createReducer(initialState, {
  [setFilesAction]: (state, { payload: { files }}={}) => ({
    ...state,
    files,
    filesLib: makeLib({data: files}),
  }),
  [setFoldersAction]: (state, { payload: { folders }}={}) => ({
    ...state,
    folders,
    foldersLib: makeLib({data: folders}),
  }),
  [addFileAction]: (state, { payload: { file }}) => {
    return {
      ...state,
      files: [...state.files, file],
      filesLib: { ...state.filesLib, [file.id]: file},
    }
  },
  [addFolderAction]: (state, { payload: { folder }}) => {
    return {
      ...state,
      folders: [...state.folders, folder],
      foldersLib: { ...state.foldersLib, [folder.id]: folder},
    }
  },
  [updateFileAction]: (state, { payload: { id, file: updatedFile }}) => ({
    ...state,
    files: state.files.map(file => file.id === id ? updatedFile : file),
    filesLib: {
      ...state.filesLib,
      [id]: {
        ...state.filesLib[id], ...updatedFile
      }
    }
  }),
  [updateFolderAction]: (state, { payload: { id, folder: updatedFolder }}) => ({
    ...state,
    folders: state.folders.map(folder => folder.id === id? updatedFolder : folder),
    foldersLib: {
      ...state.foldersLib,
      [id]: {
        ...state.foldersLib[id], ...updatedFolder
      }
    }
  }),
  [removeFilesAction]: (state, { payload: { ids }}) => {
    const files = state.files.filter(file => !ids.includes(file.id))
    return (
      {
        ...state,
        files,
        filesLib: makeLib({data: files})
      })
  },
  [removeFoldersAction]: (state, { payload: { ids }}) => {
    const folders = state.folders.filter(folder => !ids.includes(folder.id))
    return (
      {
        ...state,
        folders,
        foldersLib: makeLib({data: folders})
      })
  },
  [setFilesPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    filesPaging: paging
  }),
  [setFoldersPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    foldersPaging: paging
  }),
  [setUploadProgressAction]: (state, { payload: { uploadProgress } }) => ({
    ...state,
    uploadProgress
  }),
  [setUpdateFetchingUploadAction]: (state, { payload: { fetchingUpload } }) => ({
    ...state,
    fetchingUpload
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getFiles = (params) => {
  return async (dispatch) => {
    try {
      const response = await getFilesRequest(params)
      const { data: files, paging } = response
      const filteredDummyFiles = filterDummyFiles(files)
      dispatch(setFilesAction({ files: filteredDummyFiles }))
      if (paging) dispatch(setFilesPagingAction({ paging }))
      return files
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Files could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getFolders = (params) => {
  return async (dispatch) => {
    try {
      const response = await getFoldersRequest(params)
      const { data: folders, paging } = response
      const FoldersWithID = folders.map(f => {
        return {...f, id: `path:${f.path}`}
      })
      dispatch(setFoldersAction({ folders: FoldersWithID }))
      if (paging) dispatch(setFoldersPagingAction({ paging }))
      return folders
    }
    catch(error) {
      if (error.status === 404){
        dispatch(setFoldersAction({ folders: [] }))
        dispatch(setFoldersPagingAction({ paging: {} }))
      } else {
        console.error(`${error.name}: ${error.message}`)
        addMessage({
          text: "Folders could not be retrieved",
          subtext: error.message,
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
  }
}

export const getNextFiles = () => {
  return async (dispatch, getState) => {
    const {
      files: {
        files,
        filesPaging: oldPaging
      }
    } = getState()
    const { data: nextFiles, paging } = await getFilesRequest({next_cursor: oldPaging.next_cursor})
    const filteredDummyFiles = filterDummyFiles(nextFiles)
    dispatch(setFilesAction({ files: [...files, ...filteredDummyFiles]}))
    if (paging) dispatch(setFilesPagingAction({paging}))
  }
}

export const getNextFolders = () => {
  return async (dispatch, getState) => {
    const {
      folders: {
        folders,
        foldersPaging: oldPaging
      }
    } = getState()
    const { data: nextFolders, paging } = await getFoldersRequest({next_cursor: oldPaging.next_cursor})
    dispatch(setFoldersAction({ folders: [...folders, ...nextFolders]}))
    if (paging) dispatch(setFoldersPagingAction({paging}))
  }
}

export const addFile = (fileDataList, updateProgress) => {
  return async () => {
    try {
      const promises = fileDataList.flatMap((fileData) => {
        if (Array.isArray(fileData) && fileData.every(data => data instanceof FormData)) {
          return fileData.map(formData => addMultipartFileRequest(formData, updateProgress))
        } else {
          return [addFileRequest(fileData, updateProgress)]
        }
      })

      const results = await Promise.all(promises)

      for (const file of results) {
        if (file) {
          const { has_errors, results: fileResults } = file || {}
          if (Array.isArray(has_errors) && has_errors.length > 0) {
            throw new Error(fileResults[0].response.error.message)
          }
        }
      }

      if (results.some(file => !file)) {
        addMessage({
          text: "File could not be uploaded",
          type: MESSAGE_TYPE_ERROR,
        })
        return false
      }

      addMessage({
        text: "All files uploaded successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return true
    } catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "File could not be uploaded",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
      return false
    }
  }
}


export const addFolder = (folderData) => {
  return async () => {
    try {
      const folder = await addFolderRequest(folderData)
      addMessage({
        text: "Folder created successfully",
        type: MESSAGE_TYPE_SUCCESS
      })
      return folder

    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Folder could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const updateFileTtl = (fileIds, ttl) => {
  const objectText = fileIds.length === 1 ? "Object" : "Objects"

  return async dispatch => {
    try {
      for (const id of fileIds) {
        const updatedFile = await updateTtlRequest(id, ttl)
        dispatch(updateFileAction({id: updatedFile.id, file: updatedFile}))
      }
      addMessage({
        text: `${objectText} updated successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: `${objectText} could not be updated`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateFolder = (id, folder) => {
  return async dispatch => {
    try {
      const updatedFolder = await updateFolderRequest(id, folder)
      dispatch(updateFolderAction({id: updatedFolder.id, folder: updatedFolder}))
      addMessage({
        text: "Folder updated successfully",
        type: MESSAGE_TYPE_SUCCESS
      })
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Folder could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteItems = (filesToDelete, foldersToDelete, path) => {
  return async dispatch => {
    try {
      const responses = foldersToDelete.map(async f => {
        const originalPath = path ? path + "/" + f.slice(5) : f.slice(5)
        return deleteFoldersRequest({ "path": originalPath })
      })
      const deleteFoldersPromiseResult = foldersToDelete ? await Promise.all(responses) : []
      dispatch(removeFoldersAction({ ids: foldersToDelete }))

      const { has_errors } = filesToDelete.length ? await deleteFilesRequest({ "id[]": filesToDelete }) : []
      dispatch(removeFilesAction({ids: filesToDelete}))

      //unify succesfull message notification for the combination of folders and objects (items), just folders or just objects. Errors will be handled independently.
      if (deleteFoldersPromiseResult?.every(f => f.data.has_errors.length === 0) && !has_errors?.length){
        return addMessage({
          text: "Delete Successful",
          subtext: `Successfully deleted ${foldersToDelete.length + filesToDelete.length} item${(foldersToDelete.length + filesToDelete.length) !== 1 ? "s" : ""}`,
          type: MESSAGE_TYPE_SUCCESS,
        })
      } else {
        addMessage({
          text: "Some items could not be deleted",
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Some items could not be deleted",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getQueryParams = (tableName, tableColumns) => {
  return (dispatch, getState) => {

    const {
      authentication: {
        userInfo: {
          username
        }
      }
    } = getState()

    const filterQueryParams = getFilterQueryParams(tableName, tableColumns, username)
    const sort = getSortQuery(getTableSort(`${tableName}-${getSpace()}`, username) || {})
    return {...filterQueryParams, ...sort}
  }
}

export const getFileLink = (id, ttl) => {
  return async () => {

    try {
      const { link } = await createLinkRequest(id, ttl)
      return link
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "TTL Link could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}
export const updateProgress = (uploadProgress) => {
  return async dispatch => {
    dispatch(setUploadProgressAction({ uploadProgress }))
  }
}

export const updateFetchingUpload = (fetchingUpload) => {
  return async dispatch => {
    dispatch(setUpdateFetchingUploadAction({ fetchingUpload }))
  }
}