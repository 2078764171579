export const SUBMIT_REQUEST = "SUBMIT_REQUEST"
export const CLOSE_TAB_FROM_BELT = "CLOSE_TAB_FROM_BELT"
export const ADD_TAB_TO_BELT = "ADD_TAB_TO_BELT"
export const UPDATE_TAB_IN_BELT = "UPDATE_TAB_IN_BELT"
export const REFRESH_FUNCTION_LOG = "REFRESH_FUNCTION_LOG"
export const UPDATE_FUNCTION_LOG_SEARCH = "UPDATE_FUNCTION_LOG_SEARCH"
export const CLEAR_FUNCTION_LOG_SEARCH = "CLEAR_FUNCTION_LOG_SEARCH"
export const TOGGLE_FUNCTION_LOG_SEARCH_FILTER = "TOGGLE_FUNCTION_LOG_SEARCH_FILTER"
export const NEW_FUNCTION_LOG = "New Function Log"
export const UPDATE_SELECTED_MQTT_TOPIC = "UPDATE_SELECTED_MQTT_TOPIC"
export const OPEN_MQTT_SUBSCRIPTIONS_MODAL = "OPEN_MQTT_SUBSCRIPTIONS_MODAL"
export const CLOSE_MQTT_SUBSCRIPTIONS_MODAL = "CLOSE_MQTT_SUBSCRIPTIONS_MODAL"
export const UPDATE_MQTT_SUBSCRIPTIONS = "UPDATE_MQTT_SUBSCRIPTIONS"
export const UPDATE_MQTT_MESSAGE_LIMIT = "UPDATE_MQTT_MESSAGE_LIMIT"


// Tab events
export const addTabToBelt = ({type, id, options}) => {
  const event = new CustomEvent(ADD_TAB_TO_BELT, { detail: { type, id, options }})
  document.dispatchEvent(event)
}

export const updateTabInBelt = ({type, id, prevId, name, options}) => {
  const event = new CustomEvent(UPDATE_TAB_IN_BELT, { detail: { type, id, prevId, name, options }})
  document.dispatchEvent(event)
}

export const closeTabFromBelt = (tabId) => {
  const event = new CustomEvent(CLOSE_TAB_FROM_BELT, { detail: tabId})
  document.dispatchEvent(event)
}

// Function Logs events
export const refreshFunctionLog = (functionName) => {
  const event = new CustomEvent(`${REFRESH_FUNCTION_LOG}-${functionName}`)
  document.dispatchEvent(event)
}

export const updateFunctionLogSearch = (functionName, searchText) => {
  const event = new CustomEvent(`${UPDATE_FUNCTION_LOG_SEARCH}-${functionName}`, { detail: { searchText }})
  document.dispatchEvent(event)
}

export const clearFunctionLogSearch = (functionName) => {
  const event = new CustomEvent(`${CLEAR_FUNCTION_LOG_SEARCH}-${functionName}`)
  document.dispatchEvent(event)
}

export const toggleFunctionLogSearchFilter = (functionName, isFiltering) => {
  const event = new CustomEvent(`${TOGGLE_FUNCTION_LOG_SEARCH_FILTER}-${functionName}`, { detail: { isFiltering }})
  document.dispatchEvent(event)
}

// MQTT Inspector events
export const updateSelectedMqttTopic = (topic) => {
  const event = new CustomEvent(UPDATE_SELECTED_MQTT_TOPIC, {detail: { topic }})
  document.dispatchEvent(event)
}

export const openMqttSubscriptionsModal = () => {
  const event = new CustomEvent(OPEN_MQTT_SUBSCRIPTIONS_MODAL)
  document.dispatchEvent(event)
}

export const closeMqttSubscriptionsModal = () => {
  const event = new CustomEvent(CLOSE_MQTT_SUBSCRIPTIONS_MODAL)
  document.dispatchEvent(event)
}

export const updateMqttSubscriptions = (topics) => {
  const event = new CustomEvent(UPDATE_MQTT_SUBSCRIPTIONS, {detail: { topics }})
  document.dispatchEvent(event)
}

export const updateMqttMessageLimit = (limit) => {
  const event = new CustomEvent(UPDATE_MQTT_MESSAGE_LIMIT, {detail: { limit }})
  document.dispatchEvent(event)
}
