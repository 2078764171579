import { colors } from "assets/stylesheets/common"
import { getDarkenedColor } from "utils/misc"

import nameTitles from "./nameTitles"

const prepareHistoryDataForChart = (historyData, name) => {
  const chartData = historyData
    .filter((item) => item[name] !== undefined)
    .map((item) => ({
      timestamp: new Date(item.at).valueOf(),
      value: Array.isArray(item[name]) ? item[name].length : item[name],
    }))
    .sort((a, b) => a.timestamp - b.timestamp)

  const chartValues = {
    id: name,
    name: nameTitles[name],
    data: chartData.map((item) => [item.timestamp, item.value]),
    type: "line",
    itemStyle: {
      color: colors["blue-01-825"],
    },
    emphasis: {
      itemStyle: {
        color: getDarkenedColor(colors["blue-01-825"]),
      },
    },
  }

  return chartValues
}

export default prepareHistoryDataForChart
